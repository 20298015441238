jQuery.validator.setDefaults({
    onfocusout: function (e) {
        this.element(e);
    },
    onkeyup: false,

    highlight: function (element) {
        jQuery(element).closest('.form-select').addClass('is-invalid');
        jQuery(element).closest('.form-control').addClass('is-invalid');
    },
    unhighlight: function (element) {
        jQuery(element).closest('.form-select').removeClass('is-invalid');
        jQuery(element).closest('.form-select').addClass('is-valid');
        jQuery(element).closest('.form-control').removeClass('is-invalid');
        jQuery(element).closest('.form-control').addClass('is-valid');
    },

    errorElement: 'div',
    errorClass: 'invalid-feedback',
    errorPlacement: function (error, element) {
        if (element.parent('.input-group-prepend').length) {
            $(element).siblings(".invalid-feedback").append(error);
            //error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }
    },
});

var frmInfo = $('.frmInfo');
var frmInfoValidator = frmInfo.validate();

$('#demo').steps({
    onChange: function (currentIndex, newIndex, stepDirection) {
        if (currentIndex === 0) {
            if (stepDirection === 'forward') {
                return frmInfo.valid();
            }
            if (stepDirection === 'backward') {
                frmInfoValidator.resetForm();
            }
        }
        if (currentIndex === 1) {
            if (stepDirection === 'forward') {
                return frmInfo.valid();
            }
            if (stepDirection === 'backward') {
                frmInfoValidator.resetForm();
            }
        }
        if (currentIndex === 2) {
            if (stepDirection === 'forward') {
                return frmInfo.valid();
            }
            if (stepDirection === 'backward') {
                frmInfoValidator.resetForm();
            }
        }
        if (currentIndex === 3) {
            if (stepDirection === 'forward') {
                return frmInfo.valid();
            }
            if (stepDirection === 'backward') {
                frmInfoValidator.resetForm();
            }
        }
        if (currentIndex === 4) {
            if (stepDirection === 'forward') {
                return frmInfo.valid();
            }
            if (stepDirection === 'backward') {
                frmInfoValidator.resetForm();
            }
        }
        if (currentIndex === 5) {
            if (stepDirection === 'forward') {
                return frmInfo.valid();
            }
            if (stepDirection === 'backward') {
                frmInfoValidator.resetForm();
            }
        }
        return true;
    },
    onFinish: function () {
        // alert('Matrícula realizada com sucesso');
        // window.location.replace("index.php?page=matricula-realizada");
        console.log('scripts')
        ConferePreenchimentoForm()
    }
});

let myNav = document.getElementById('navbar');
window.onscroll = function () {
    "use strict";
    if (document.body.scrollTop >= 60 || document.documentElement.scrollTop >= 60) {
        myNav.classList.add("nav-colored");
        myNav.classList.remove("nav-transparent");
    } else {
        myNav.classList.add("nav-transparent");
        myNav.classList.remove("nav-colored");
    }
};

$('.carousel-main').flickity({
    pageDots: false,
    prevNextButtons: false
});
// 2nd carousel, navigation
$('.carousel-nav').flickity({
    asNavFor: '.carousel-main',
    contain: true,
    pageDots: false,
    prevNextButtons: false,
    cellAlign: 'left'
});

//Masks
$('.telefone_aluno').mask('00 000000000');
$('.telefone_pai').mask('00 000000000');
$('.telefone_mae').mask('00 000000000');
$('.telefone_resp_fin').mask('00 000000000');
$('.nascimento_aluno').mask("00/00/0000");
$('.nascimento_pai').mask("00/00/0000");
$('.nascimento_mae').mask("00/00/0000");
$('.nascimento_resp_fin').mask("00/00/0000");
$(".cep").mask('00000-000', { reverse: true });
$(".cep_pai").mask('00000-000', { reverse: true });
$(".cep_mae").mask('00000-000', { reverse: true });
$(".cep_resp_fin").mask('00000-000', { reverse: true });
$(".cpf_aluno").mask('00000000000', { reverse: true });
$(".cpf_pai").mask('00000000000', { reverse: true });
$(".cpf_mae").mask('00000000000', { reverse: true });
$(".cpf_resp_fin").mask('00000000000', { reverse: true });

//Zipcode
$('.zip_code_search_aluno').blur(function () {

    function emptyForm() {
        $(".rua").val("");
        $(".bairro").val("");
        $(".cidade_aluno").val("");
        $(".estado_aluno").val("");
    }

    const cep = $(this).val().replace(/\D/g, '');
    const validate_zip_code = /^[0-9]{8}$/;

    // noinspection EqualityComparisonWithCoercionJS
    if (cep != "" && validate_zip_code.test(cep)) {

        $(".rua").val("");
        $(".bairro").val("");
        $(".cidade_aluno").val("");
        $(".estado_aluno").val("");

        $.getJSON("https://viacep.com.br/ws/" + cep + "/json/?callback=?", function (data) {

            if (!("erro" in data)) {
                // noinspection JSUnresolvedVariable
                $(".rua").val(data.logradouro);
                // noinspection JSUnresolvedVariable
                $(".bairro").val(data.bairro);
                // noinspection JSUnresolvedVariable
                $("#estado_aluno option[data-value='" + data.uf + "']").attr("selected", "selected");
                // noinspection JSUnresolvedVariable
                buscar_cidades('estado_aluno', 'aluno', data.localidade);
                // $("#cidade_aluno").val(data.localidade);
            } else {
                emptyForm();
                alert("CEP não encontrado.");
            }

            console.log('buscando cep do aluno')
            console.log(data)
        });
    } else {
        emptyForm();
        alert("Formato de CEP inválido.");
    }
});

$('.zip_code_search_pai').blur(function () {

    function emptyForm() {
        $(".rua_pai").val("");
        $(".bairro_pai").val("");
        $(".cidade_pai").val("");
        $(".estado_pai").val("");
    }

    const cep_pai = $(this).val().replace(/\D/g, '');
    const validate_zip_code = /^[0-9]{8}$/;

    // noinspection EqualityComparisonWithCoercionJS
    if (cep_pai != "" && validate_zip_code.test(cep_pai)) {

        $(".rua_pai").val("");
        $(".bairro_pai").val("");
        $(".cidade_pai").val("");
        $(".estado_pai").val("");

        $.getJSON("https://viacep.com.br/ws/" + cep_pai + "/json/?callback=?", function (data) {

            if (!("erro" in data)) {
                // noinspection JSUnresolvedVariable
                $(".rua_pai").val(data.logradouro);
                // noinspection JSUnresolvedVariable
                $(".bairro_pai").val(data.bairro);
                // noinspection JSUnresolvedVariable
                $(".cidade_pai").val(data.localidade);
                // noinspection JSUnresolvedVariable
                $(".estado_pai").val(data.uf);
            } else {
                emptyForm();
                alert("CEP não encontrado.");
            }
        });
    } else {
        emptyForm();
        alert("Formato de CEP inválido.");
    }
});

$('.zip_code_search_mae').blur(function () {

    function emptyForm() {
        $(".rua_mae").val("");
        $(".bairro_mae").val("");
        $(".cidade_mae").val("");
        $(".estado_mae").val("");
    }

    const cep_mae = $(this).val().replace(/\D/g, '');
    const validate_zip_code = /^[0-9]{8}$/;

    // noinspection EqualityComparisonWithCoercionJS
    if (cep_mae != "" && validate_zip_code.test(cep_mae)) {

        $(".rua_mae").val("");
        $(".bairro_mae").val("");
        $(".cidade_mae").val("");
        $(".estado_mae").val("");

        $.getJSON("https://viacep.com.br/ws/" + cep_mae + "/json/?callback=?", function (data) {

            if (!("erro" in data)) {
                // noinspection JSUnresolvedVariable
                $(".rua_mae").val(data.logradouro);
                // noinspection JSUnresolvedVariable
                $(".bairro_mae").val(data.bairro);
                // noinspection JSUnresolvedVariable
                $(".cidade_mae").val(data.localidade);
                // noinspection JSUnresolvedVariable
                $(".estado_mae").val(data.uf);
            } else {
                emptyForm();
                alert("CEP não encontrado.");
            }
        });
    } else {
        emptyForm();
        alert("Formato de CEP inválido.");
    }
});

$('.zip_code_search_resp_fin').blur(function () {

    function emptyForm() {
        $(".rua_resp_fin").val("");
        $(".bairro_resp_fin").val("");
        $(".cidade_resp_fin").val("");
        $(".estado_resp_fin").val("");
    }

    const cep_resp_fin = $(this).val().replace(/\D/g, '');
    const validate_zip_code = /^[0-9]{8}$/;

    // noinspection EqualityComparisonWithCoercionJS
    if (cep_resp_fin != "" && validate_zip_code.test(cep_resp_fin)) {

        $(".rua_resp_fin").val("");
        $(".bairro_resp_fin").val("");
        $(".cidade_resp_fin").val("");
        $(".estado_resp_fin").val("");

        $.getJSON("https://viacep.com.br/ws/" + cep_resp_fin + "/json/?callback=?", function (data) {

            if (!("erro" in data)) {
                // noinspection JSUnresolvedVariable
                $(".rua_resp_fin").val(data.logradouro);
                // noinspection JSUnresolvedVariable
                $(".bairro_resp_fin").val(data.bairro);
                // noinspection JSUnresolvedVariable
                $(".cidade_resp_fin").val(data.localidade);
                // noinspection JSUnresolvedVariable
                $(".estado_resp_fin").val(data.uf);
            } else {
                emptyForm();
                alert("CEP não encontrado.");
            }
        });
    } else {
        emptyForm();
        alert("Formato de CEP inválido.");
    }
});


// App code
console.log(`Olá ${process.env.HELLO}`);
